module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"background":"rgba(0, 0, 0, .4)","zIndex":99},"content":{"border":"0","borderRadius":"4px","bottom":"auto","minHeight":"10rem","left":"50%","padding":"2rem","position":"fixed","right":"auto","top":"50%","transform":"translate(-50%,-50%)","minWidth":"280px","width":"80%","maxWidth":"60rem","maxHeight":"calc(100% - 48px)"}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
